import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import Upgrade from './components/Upgrade';
import NotFound from './components/NotFound';
import { RpcProvider } from './contexts/RpcContext';
import { AppProvider, useApp } from './contexts/AppContext';

function App() {
  return (
    <AppProvider>
      <RpcProvider>
        <Router>
          <div className="container">
            <Navbar />
            <Content />
          </div>
        </Router>
      </RpcProvider>
    </AppProvider>
  );
}

function Content() {
  const { loading, error, info } = useApp();
  if (loading || error || info)
    return (
      <div>
        {loading ? (
          <div className='text-center'><div className='app-loading'>Loading...</div><br /><br /><small><a href='#' onClick={() => { window.location.reload() }} >Reload Page</a></small></div>
        ) : null}
        {info ? (
          <div className='text-center'><div className='app-info'>Info: {info}</div><br /><br /><small><a href='#' onClick={() => { window.location.reload() }} >Reload Page</a></small></div>
        ) : null}
        {error ? (
          <div className='text-center'><div className='app-error'>Error: {error}</div><br /><br /><small><a href='#' onClick={() => { window.location.reload() }} >Reload Page</a></small></div>
        ) : null}
      </div>
    );

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/upgrade" element={<Upgrade />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
