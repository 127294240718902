import React, { useState, useEffect } from 'react';
import { useRpc, RpcProvider } from '../contexts/RpcContext';
import { useApp } from '../contexts/AppContext';
import { Buffer } from 'buffer';
import { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp';

const CHROME_EXT_URL =
  'https://chrome.google.com/webstore/detail/polkadot%7Bjs%7D-extension/mopnmbcafieddcagagdcbnhejhlodfdd'
const FIREFOX_ADDON_URL =
  'https://addons.mozilla.org/en-US/firefox/addon/polkadot-js-extension/'


const Upgrade = () => {
  const { rpcEndpoint, setRpcEndpoint, api, isApiReady, keyring, keyringReady, allAccounts } = useRpc();
  const { setLoading, setError, setInfo } = useApp();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [chainUpgradeInfo, setChainUpgradeInfo] = useState(null);
  const [chainLog, setChainLog] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [upgradeResult, setUpgradeResult] = useState(null);

  useEffect(() => {
    let unsubscribeNewHeads;
    let unsubscribeEvents;

    if (isApiReady && !isSubscribed) {
      api.rpc.chain.subscribeNewHeads((header) => {
        setIsSubscribed(true);
        console.log(`Chain is at block: #${header.number}`);
      }).then((unsub) => {
        unsubscribeNewHeads = unsub;
        return api.query.system.events((events) => {
          events.forEach((record) => {
            const { event, phase } = record;
            const types = event.typeDef;
            const eventName = `${event.section}:${event.method}:: (phase=${phase.toString()})`;

            const args = event.data.map((data, index) => `${types[index].type}: ${data.toString()}`);
            console.log(`\t${eventName}:: ${args.join(', ')}`);

            if (event.section === 'sudo') {
              // // get the executed hex-encoded extrinsic call
              // const [dispatched] = event.data;
              // const dispatchedHex = dispatched.toHex();
              // // get the encoding details
              // const encoded = api.tx.sudo.decodeDispatch(dispatchedHex);
              // // find the call and all arguments
              // const { args, callIndex, method, section } = encoded.method;
              setChainLog(chainLog => [...chainLog, `\t${eventName}:: ${args.join(', ')}`]);
            }
          });
        });
      }).then((unsub) => {
        unsubscribeEvents = unsub;
      }).catch(console.error);
    }

    // Cleanup effect to unsubscribe when component unmounts or on other changes
    return () => {
      unsubscribeNewHeads && unsubscribeNewHeads();
      unsubscribeEvents && unsubscribeEvents();
    };
  }, [isApiReady, isSubscribed]);


  useEffect(() => {
    if (keyringReady) {
      setAccounts(allAccounts)
      setSelectedAccount(allAccounts[0].address)
    }
  }, [keyringReady]);

  useEffect(() => {
    setChainUpgradeInfo(null);
    if (selectedAccount) {
      console.log(selectedAccount);
    }
  }, [selectedAccount]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setChainUpgradeInfo(null);
  }
  const previewUpgrade = () => {
    if (!selectedAccount) {
      alert('Please select an account.');
      return;
    }
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }
    const chainUpgradeInfo = {
      account: selectedAccount,
      file: selectedFile
    };
    setChainUpgradeInfo(chainUpgradeInfo);
  }

  const executeUpgrade = async () => {
    if (!selectedAccount) {
      alert('Please select an account.');
      return;
    }
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }
    // confirm one more time
    if (!window.confirm('Are you sure you want to upgrade the chain runtime?')) {
      return;
    }

    const chainUpgradeInfo = {
      account: selectedAccount,
      file: selectedFile
    };
    setChainUpgradeInfo(chainUpgradeInfo);

    const fileReader = new FileReader();
    fileReader.onloadend = async () => {
      const fileBuffer = fileReader.result;
      const fileBytes = new Uint8Array(fileBuffer);
      const fileBytesHex = '0x' + Buffer.from(fileBytes).toString('hex');
      setLoading(true);

      // first get the account from allAccounts
      const signer = allAccounts.find(account => account.address === selectedAccount);
      console.log(signer);
      try {
        let upgradeResult;
        if (signer.meta.source == 'polkadot-js') {
          console.log('polkadot-js signer');
          const injector = await web3FromSource(signer.meta.source);
          upgradeResult = await api.tx.sudo
            .sudoUncheckedWeight(api.tx.system.setCode(fileBytesHex), 0)
            .signAndSend(signer.address, { signer: injector.signer });
        } else {
          // this sould be only dev accounts that execute this
          upgradeResult = await api.tx.sudo
            .sudoUncheckedWeight(api.tx.system.setCode(fileBytesHex), 0)
            .signAndSend(keyring.getPair(signer.address));
        }

        console.log(upgradeResult.toHuman());
        setUpgradeResult(upgradeResult.toHuman());
        setChainUpgradeInfo(null);
        setLoading(false);
      } catch (error) {
        if (error.message == 'Cancelled') {
          setLoading(false);
          return;
        }
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    }
    fileReader.readAsArrayBuffer(selectedFile)
  }

  return (
    <div className="container mt-5">
      <h1>Upgrade Chain Runtime</h1>
      <div className='row mb-2'>
        <div className="alert alert-warning" role="alert">
          <h2>Prerequisites</h2>
          <p>
            Before upgrading the chain runtime, make sure that you have the compact and compressed WebAssembly file that you generated for the updated runtime.
            <br />
            It should be named something like <mark><em>bcf_runtime.compact.compressed.wasm</em></mark>.
          </p>
        </div>
        <h2>Checklist</h2>
        <ol>
          <li>Make sure the new runtime has upgraded its <code>spec_version</code> in the <code>runtime_version</code> macro</li>
          <li>If required, edit other info in the <code>runtime_version</code> macro</li>
          <li>
            Recompile and test the new runtime before anything else.
            <br />
            <code>cargo build --release</code>
          </li>
          <li>
            <span className='text-danger'><i className="bi bi-exclamation-triangle"></i> Make sure the new node runs nicely !</span>
          </li>
          <li>
            Get the new build WebAssembly artifacts. They should be in the <code>target/release/wbuild/bcf-runtime/</code> directory.
            <br />
            <ul>
              <li>bcf_runtime.compact.compressed.wasm</li>
              <li>bcf_runtime.compact.wasm</li>
              <li>bcf_runtime.wasm</li>
            </ul>
          </li>
          <li>You're ready. More info <a href='https://docs.substrate.io/tutorials/build-a-blockchain/upgrade-a-running-network' target='_blank'>here</a>.</li>
        </ol>
      </div>


      <div className='row mb-2'>
        <h2>Upload the new runtime</h2>
        {!selectedAccount ? (
          <span>
            Create an account with Polkadot-JS Extension (
            <a target="_blank" rel="noreferrer" href={CHROME_EXT_URL}>
              Chrome
            </a>
            ,&nbsp;
            <a target="_blank" rel="noreferrer" href={FIREFOX_ADDON_URL}>
              Firefox
            </a>
            )&nbsp;
          </span>
        ) : null}
        {accounts.length > 0 ? (
          <div className="mb-3">
            <label htmlFor="accountSelector" className="form-label">Choose Account</label>
            <select id="accountSelector" className="form-select" value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
              {/* <option value="">Select an account...</option> */}
              {accounts.map(account => (
                <option key={account.address} value={account.address}>{account.meta.name} ({account.address})</option>
              ))}
            </select>
          </div>
        ) : (
          <p>No accounts found. Please install or unlock the Polkadot.js extension.</p>
        )}
        <div className="mb-3">
          <input type="file" className="form-control" onChange={handleFileChange} />
        </div>
        <div className="mb-3">
          <button className="btn btn-primary" onClick={() => previewUpgrade()}>Preview chain runtime upgrade</button>
        </div>
        <div className="mb-3">
          {chainUpgradeInfo ? (
            <div className="alert alert-info" role="alert">
              <h2>Chain Runtime Upgrade Preview</h2>
              <div className='text-center'>
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Account executing the transaction</th>
                      <td>{chainUpgradeInfo.account}</td>
                    </tr>
                    <tr>
                      <th scope="row">WASM Filename</th>
                      <td>{chainUpgradeInfo.file.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">WASM Filesize</th>
                      <td>{chainUpgradeInfo.file.size} bytes</td>
                    </tr>

                    <tr>
                      <th scope='row'>Current chain</th>
                      <td>
                        <table className="table border">
                          <tbody>
                            <tr>
                              <th scope="row">Endpoint</th>
                              <td>{rpcEndpoint}</td>
                            </tr>
                            <tr>
                              <th scope="row">Chain name</th>
                              <td>{api.runtimeVersion.specName.toString()}</td>
                            </tr>
                            <tr>
                              <th scope="row">Current chain version</th>
                              <td>{api.runtimeVersion.specVersion.toString()}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='btn-group'>
                  <button className="btn btn-success btn-lg" onClick={() => executeUpgrade()}>Execute chain runtime upgrade</button>
                  <button className="btn btn-danger btn-lg" onClick={() => setChainUpgradeInfo(null)}>Cancel</button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mb-3">
          <h2>Chain log</h2>
          <span>Showing all <code>Sudo</code> calls.</span>
          <pre className='border p-3' style={{ minHeight: "100px", maxHeight: "300px" }}>
            {chainLog.map((log, index) => (
              <div key={index}>{log}</div>
            ))}
          </pre>
        </div>
      </div>

    </div>
  );
}

export default Upgrade;
