import { React, useState, useEffect } from 'react';
import { useRpc, RpcProvider } from '../contexts/RpcContext';
import { useApp } from '../contexts/AppContext';


const EndpointWithCopyButton = ({ rpcEndpoint }) => {
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(rpcEndpoint).then(function () {
            console.log('Copying to clipboard was successful!');
        }, function (err) {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="input-group mb-1">
            <input type="text" className="form-control" value={rpcEndpoint} readOnly />
            <button className="btn btn-outline-secondary" type="button" onClick={handleCopyToClipboard}>
                <i className="bi bi-clipboard"></i>
            </button>
        </div>
    );
};


const Dashboard = () => {
    const { setLoading, setError } = useApp();
    const { rpcEndpoint, setRpcEndpoint, api, isApiReady } = useRpc();
    const [blockNumber, setBlockNumber] = useState(0);
    const [chainData, setChainData] = useState({});
    const [chainDataLoaded, setChainDataLoaded] = useState(false);
    useEffect(() => {
        if (isApiReady) {
            Promise.allSettled([
                api.rpc.system.chain(),
                api.rpc.system.version(),
                api.rpc.chain.getBlockHash(0),
                api.rpc.state.getRuntimeVersion(),
                api.rpc.system.health(),
                api.rpc.system.localPeerId(),
                api.rpc.system.syncState(),
                api.rpc.system.nodeRoles(),
                api.rpc.system.peers(),
                api.rpc.system.properties()
            ]).then((results) => {
                const [
                    chainName,
                    chainVersion,
                    genesis,
                    runtimeVersion,
                    health,
                    localPeerId,
                    syncState,
                    nodeRoles,
                    peers,
                    properties
                ] = results.map(r => r.status === 'fulfilled' ? r.value : undefined);

                setChainData({
                    name: chainName,
                    version: chainVersion,
                    genesisHash: genesis,
                    runtimeVersion: runtimeVersion,
                    health: health,
                    localPeerId: localPeerId,
                    syncState: syncState,
                    nodeRoles: nodeRoles,
                    peers: peers,
                    properties: properties
                });
                setChainDataLoaded(true);
            })
                .catch((err) => {
                    console.error(err);
                    setError(`API Error: ${err.message}`);
                })
        } else {
            setBlockNumber(0);
            setChainData({});
        }
    }, [api, isApiReady]);


    // only show live data after chain data has been loaded
    useEffect(() => {
        if (isApiReady && chainDataLoaded) {
            let unsubscribeAll = null;
            api.rpc.chain.subscribeNewHeads((header) => {
                // update also chain data health, sync state, peers
                Promise.allSettled([
                    api.rpc.system.chain(),
                    api.rpc.system.version(),
                    api.rpc.system.health(),
                    api.rpc.system.syncState(),
                    api.rpc.system.peers()
                ]).then((results) => {
                    const [
                        chainName,
                        chainVersion,
                        health,
                        syncState,
                        peers
                    ] = results.map(r => r.status === 'fulfilled' ? r.value : undefined);

                    setChainData(prevData => ({
                        ...prevData,
                        name: chainName,
                        version: chainVersion,
                        health: health,
                        syncState: syncState,
                        peers: peers
                    }));
                    setBlockNumber(header.number.toNumber());
                })
                    .catch((err) => {
                        console.error(err);
                        setError(`API Error: ${err.message}`);
                        // setBlockNumber(0);
                        // setChainData({});
                    })
            });
            return () => unsubscribeAll && unsubscribeAll();
        }
    }, [api, isApiReady, chainDataLoaded]);


    const displayChainData = () => {
        return (
            <div>
                <div className='row'>
                    <h1>Dashboard</h1>
                </div>
                <div className="row mb-2">
                    <div className="row g-0 border rounded mb-4 shadow-sm h-md-250">
                        <div className="col p-4 d-flex flex-column position-static">
                            {/* <strong className="d-inline-block mb-2 text-primary-emphasis">Chain data</strong> */}
                            <h3 className="mb-0">{chainData.name} <small className='text-body-secondary'>({blockNumber})</small></h3>
                            {/* <div className="mb-1 text-body-secondary">{rpcEndpoint}</div> */}
                            <EndpointWithCopyButton rpcEndpoint={rpcEndpoint} />
                            <div className="card-text mb-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-striped-columns">
                                        <thead>
                                            <tr>
                                                <th>Property</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Chain name</td>
                                                <td>{chainData.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Chain version</td>
                                                <td>{chainData.version}</td>
                                            </tr>
                                            <tr>
                                                <td>Runtime version</td>
                                                <td>{chainData.runtimeVersion.specVersion.toString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Node roles</td>
                                                <td>{chainData.nodeRoles[0].toString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Genesis hash</td>
                                                <td>{chainData.genesisHash.toString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Local peer ID</td>
                                                <td>{chainData.localPeerId.toString()}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>Properties</td>
                                            </tr>
                                            <tr>
                                                <td>Token decimals:</td>
                                                <td>{chainData.properties.tokenDecimals.toHuman()}</td>
                                            </tr>
                                            <tr>
                                                <td>Token symbol:</td>
                                                <td>{chainData.properties.tokenSymbol.toHuman()}</td>
                                            </tr>
                                            <tr>
                                                <td>SS58 format:</td>
                                                <td>{chainData.properties.ss58Format.toString()}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>Health</td>
                                            </tr>
                                            <tr>
                                                <td>Peers:</td>
                                                <td>{chainData.health.peers.toString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Is synced:</td>
                                                <td>{chainData.health.isSyncing ? 'No' : 'Yes'}</td>
                                            </tr>
                                            <tr>
                                                <td>Should have peers:</td>
                                                <td>{chainData.health.shouldHavePeers ? 'Yes' : 'No'}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>Sync State</td>
                                            </tr>
                                            <tr>
                                                <td>Starting block:</td>
                                                <td>{chainData.syncState.startingBlock.toString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Current block:</td>
                                                <td>{chainData.syncState.currentBlock.toString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Highest block:</td>
                                                <td>{chainData.syncState.highestBlock.toString()}</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    {chainData.peers ? (
                                        <div>
                                            <h4>Peers (Total: {chainData.peers.length}):</h4>
                                            <table className="table table-sm table-striped-columns">
                                                <thead>
                                                    <tr>
                                                        <th>Peer ID</th>
                                                        <th>Info</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {chainData.peers.length > 0 ? chainData.peers && chainData.peers.map((peer, index) => (
                                                        <tr key={index}>
                                                            <td>{peer.peerId.toString()}</td>
                                                            <td>
                                                                <strong>Roles:</strong> {peer.roles.toString()}<br />
                                                                <strong>Protocol Version:</strong> {peer.protocolVersion.toString()}<br />
                                                                <strong>Best Hash:</strong> {peer.bestHash.toString()}<br />
                                                                <strong>Best Number:</strong> {peer.bestNumber.toHuman()}
                                                            </td>
                                                        </tr>
                                                    )) : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            {Object.keys(chainData).length > 0 ? displayChainData() : null}
        </div>
    );
}

export default Dashboard;
