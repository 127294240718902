import { createContext, useState, useEffect, useContext } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { useApp } from './AppContext';
import { keyring as Keyring } from '@polkadot/ui-keyring'
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import { isTestChain } from '@polkadot/util'

export const RpcContext = createContext();

export const useRpc = () => {
    return useContext(RpcContext);
};

export const RpcProvider = ({ children }) => {
    const { setLoading, setError, setInfo } = useApp();
    const [rpcEndpoint, setRpcEndpoint] = useState(''); // default RPC endpoint
    const [api, setApi] = useState(null);
    const [isApiReady, setIsApiReady] = useState(false);
    const [keyring, setKeyring] = useState(null);
    const [keyringReady, setKeyringReady] = useState(false);
    const [allAccounts, setAllAccounts] = useState([]);
    const [accountLoaded, setAccountLoaded] = useState(false);

    useEffect(() => {
        if (!isApiReady) return;
        if (keyring) return;
        if (accountLoaded) return;
        if (!rpcEndpoint) return;
        // Enable the web3 extension
        const enableExtension = async () => {
            await web3Enable('BCF Network Admin');
            // Load all the accounts
            let all_accounts = await web3Accounts()

            all_accounts = all_accounts.map(({ address, meta }) => ({
                address,
                meta: { ...meta, name: `${meta.name} (${meta.source})` },
            }))

            // Logics to check if the connecting chain is a dev chain, coming from polkadot-js Apps
            // ref: https://github.com/polkadot-js/apps/blob/15b8004b2791eced0dde425d5dc7231a5f86c682/packages/react-api/src/Api.tsx?_pjax=div%5Bitemtype%3D%22http%3A%2F%2Fschema.org%2FSoftwareSourceCode%22%5D%20%3E%20main#L101-L110
            const { systemChain, systemChainType } = await retrieveChainInfo(api)
            const isDevelopment =
                systemChainType.isDevelopment ||
                systemChainType.isLocal ||
                isTestChain(systemChain)
            console.log("isDevelopment", isDevelopment)
            Keyring.loadAll({ isDevelopment })
            if (isDevelopment) {
                Keyring.getPairs().forEach(account => {
                    const { address } = account;
                    const meta = account.meta;
                    all_accounts.push({ address, meta });
                });
            }
            setKeyring(Keyring);
            setAllAccounts(all_accounts);
            setAccountLoaded(true);
            setKeyringReady(true);
        }
        enableExtension();
    }, [isApiReady]);

    const retrieveChainInfo = async api => {
        const [systemChain, systemChainType] = await Promise.all([
            api.rpc.system.chain(),
            api.rpc.system.chainType
                ? api.rpc.system.chainType()
                : Promise.resolve(registry.createType('ChainType', 'Live')),
        ])

        return {
            systemChain: (systemChain || '<unknown>').toString(),
            systemChainType,
        }
    }


    useEffect(() => {
        setLoading(true);
        let wsProvider;
        let apiInstance;


        if (!rpcEndpoint) {
            setLoading(false);
            setError(null);
            setInfo("Please select a network.");
            return;
        }

        // on rpcEndpoint change, save it to local storage
        localStorage.setItem('rpcEndpoint', rpcEndpoint);


        const initializeApi = async () => {
            try {
                wsProvider = new WsProvider(rpcEndpoint, 5000);
                apiInstance = new ApiPromise({ provider: wsProvider });

                wsProvider.on('error', () => {
                    setLoading(false);
                    setIsApiReady(false);
                    setError(`RPC Provider Error`);
                });
                wsProvider.on('connected', () => {
                    setError(null);
                    setInfo(null);
                });
                apiInstance.isReady.then(() => {
                    setApi(apiInstance);
                    setIsApiReady(true);
                    setLoading(false);
                }).catch((err) => {
                    setIsApiReady(false);
                    setError(`API Error: ${err.message}`);
                });
            } catch (err) {
                setLoading(false);
                setError("Error connecting to RPC endpoint.");
                console.error(err);
            }
        };

        initializeApi();

        return () => {
            if (apiInstance) {
                apiInstance.disconnect();
            }

            if (wsProvider) {
                // wsProvider.removeAllListeners();
            }
        };
    }, [rpcEndpoint]);



    useEffect(() => {
        // get the local storage for previously selected rpc endpoint
        const rpcEndpoint = localStorage.getItem('rpcEndpoint');
        if (rpcEndpoint) {
            setRpcEndpoint(rpcEndpoint);
        }
    }, []);


    const value = {
        rpcEndpoint,
        setRpcEndpoint,
        api,
        isApiReady,
        keyring,
        keyringReady,
        allAccounts
    };

    return <RpcContext.Provider value={value}>{children}</RpcContext.Provider>;
};
