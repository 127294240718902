import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRpc } from '../contexts/RpcContext';

const Navbar = () => {
  const { rpcEndpoint, setRpcEndpoint, api, isApiReady } = useRpc();
  const [customRpc, setCustomRpc] = useState('');

  const handleRpcChange = (endpoint) => {
    setRpcEndpoint(endpoint);
    setCustomRpc(endpoint);
  };

  const getRpcStatus = () => {
    if (isApiReady && rpcEndpoint) {
      return <span className="text-success">Connected</span>;
    } else {
      return <span className="text-danger">Not Connected</span>;
    }
  }

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary rounded">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">BCF-Network Dashboard</Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/upgrade">Upgrade Chain Runtime</Link>
            </li>
          </ul>

          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <span className="nav-link">{getRpcStatus()}</span>
            </li>
            <li className="nav-item dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {rpcEndpoint ? rpcEndpoint : 'Select network'}
              </button>
              <ul className="dropdown-menu">
                {/* Default is an empty option */}
                <li>
                  <button className="dropdown-item" onClick={() => handleRpcChange('')}>Select network</button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleRpcChange('wss://ws.dev.bcf-lab.com')}>BCF Testnet</button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleRpcChange('ws://127.0.0.1:9944')}>Default localhost</button>
                </li>
                <li>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Custom RPC"
                    value={customRpc}
                    onChange={(e) => setCustomRpc(e.target.value)}
                  />
                  <button className="btn btn-primary btn-sm mt-2 ml-2" onClick={() => handleRpcChange(customRpc)}>
                    Set Custom RPC
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
